<!-- 消息中心 -->
<template>
  <div id="UserMessage">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">{{ $t('message.tabIndex1') }}</el-menu-item>
      <el-menu-item index="2">{{ $t('message.tabIndex2') }}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "UserMessage",
  data() {
    return {
      activeIndex: "1",
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    handleSelect(e) {
      this.activeIndex = e;
      console.log("切换了");
    },
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
</style>