
let privateMoney = function (value) {
	if (value != null || value != undefined) {
		if (value.toString().length < 7) {
			return value
		} else if (value.toString().length >= 7 && value.toString().length < 10) {
			return (parseFloat(value / 1000000).toFixed(0)).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") + 'M'
		} else if (value.toString().length >= 10) {
			return (parseFloat(value / 1000000000).toFixed(0)).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") + 'TY'
		}
	} else {
		return value = 0
	}
}

let formatNum = function (num) {       //一位或三位数字，后边是 有三位数组一组的(1个或多个), 然后匹配结束或者. ,?=,?:参与格式匹配，不参与结果匹配,$1为 (\d{1,3})的匹配结果
	if (num) {
		return String(num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
	} else {
		return 0;
	}
}

export { privateMoney, formatNum }