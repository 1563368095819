<!--  -->
<template>
  <div id="Index">
    <!-- 内容 -->
    <div class="cont useranimated" :class="[isShow ? 'zoomIn' : 'zoomOut']">
      <!-- 标题头部 -->
      <div class="head">
        <i class="iconfont icon-yonghutianchong user"></i>
        <h1>{{ $t("header.grzx") }}</h1>
        <i class="iconfont icon-guanbi close" @click="close"></i>
      </div>
      <!-- 中间部分 -->
      <div class="middle">
        <!-- 左侧菜单 -->
        <div class="left">
          <ul>
            <li
              @click="changeTab(item)"
              :class="{ activted: item.id + '' === activeIndex + '' }"
              v-for="(item, index) in menuList"
              :key="index"
            >
              <div>
                <div class="tab">
                  <img :src="item.imgUrl" alt="" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 右侧内容部分 -->
        <div class="right">
          <UserProCom :tabIndex="detilesTabIndex" v-if="activeIndex + '' === '1'" />
          <Recharge v-if="activeIndex + '' === '2'" />
          <Withdraw @jumpTab="jumpTab" v-if="activeIndex + '' === '3'" />
          <UserSecurity v-if="activeIndex + '' === '4'" />
          <UserRecords v-if="activeIndex + '' === '5'" />
          <UserF v-if="activeIndex + '' === '6'" />
          <UserMessage v-if="activeIndex + '' === '7'" />
          <RebateRules v-if="activeIndex + '' === '8'" />
        </div>
      </div>
    </div>
    <!-- 背景 -->
    <div class="bg" @click="close"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserProCom from "../UserProCom/UserProCom";
import Recharge from "../Recharge";
import Withdraw from "../Withdraw";
import UserSecurity from "../UserSecurity"
import UserRecords from "../UserRecords";
import UserF from "../UserF";
import UserMessage from "../UserMessage"
import RebateRules from "../RebateRules"
export default {
  name: "",
  components: { UserProCom, Recharge, Withdraw, UserSecurity, UserRecords, UserF, UserMessage, RebateRules },
  data() {
    return {
      isShow: true,
      menuList: [
        {
          id: 1,
          name: this.$t("profile.info"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/8ef4a33cba6a320d28ef698cf8793072.png",
        },
        {
          id: 2,
          name: this.$t("profile.cz"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/fd7c47a585fdf3c917a187ae8df5ef79.png",
        },
        {
          id: 3,
          name: this.$t("profile.tx"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/ad97b185c03ec5e7b15af607540fe808.png",
        },
        {
          id: 4,
          name: this.$t("profile.aqzx"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/4f6b752b940e8c28a60cdb583d3ba2c2.png",
        },
        {
          id: 5,
          name: this.$t("profile.tzjl"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/5ec638e4fb840152b1a7552098383754.png",
        },
        {
          id: 6,
          name: this.$t("profile.zjmx"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/e218170d51d2516820ecb4bc9fe66c4b.png",
        },
        {
          id: 7,
          name: this.$t("profile.xxzx"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/8564baf95d42f2640b878f0d609d7303.png",
        },
        {
          id: 8,
          name: this.$t("profile.flgz"),
          imgUrl:
            "https://res-qn.ghweixiu.com/jfdev/a9aba5b78c96c607049c13f9bc86f769.png",
        },
      ],
      detilesTabIndex: '1',
      activeIndex: 1,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    ...mapActions(["closeMainDialog"]),
    changeTab(item) {
      this.activeIndex = item.id;
    },
    jumpTab(item) {
      if (item.tabIndex) {
          this.detilesTabIndex = item.tabIndex + '';
      }else {
          this.detilesTabIndex = '1';
      }
      this.activeIndex = item.menuId;
    },
    close() {
      this.isShow = false;
      this.closeMainDialog();
    },
  },
};
</script>
<style scoped lang="less">
@import "../css/tabs.less";
/* 背景部分 */
#Index .bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  z-index: -9;
}
/* 主体部分 */
#Index {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

#Index .cont {
  width: 65%;
  min-width: 1390px;
  margin: auto;
  margin-top: 10vh;
  z-index: 100;
}

#app .theme-color1 #Index > .cont {
  border: 1px solid #9b9894;
  border-radius: 10px;
}
/* 头部 */
#Index .cont .head {
  position: relative;
  width: 100%;
  display: flex;
  height: 52px;
  line-height: 52px;
  background-color: #8841e0;
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background: url(../../assets/img/smdt.png);
  background-size: cover;
}

#Index .cont .head .user {
  margin-left: 28px;
}
#Index .cont .head .iconfont {
  color: #fff;
  font-size: 18px;
}

#Index .cont .head h1 {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  color: #fff;
  margin-left: 12 px;
}

#Index .cont .head .close {
  position: absolute;
  right: 15px;
}

/* 中间部分 */
#Index .cont .middle {
  display: flex;
  height: 70vh;
  background-color: #fff;
}

#Index > .cont .middle > .left {
  background-color: #050505;
}

#Index .cont .middle .left {
  min-width: 175px;
  overflow-y: auto;
}

#Index > .cont .middle > .right {
  background-color: #222127;
}

#Index .cont .middle .right {
  flex: 1;
  overflow: auto;
}

#Index .cont .middle .left ul li.activted {
  color: #fff;
}
#Index .cont .middle .left ul li {
  padding: 10px;
}

#Index > .cont .middle > .left ul li.activted .tab {
  background-image: linear-gradient(90deg, #f7e59b, #caa265 43%, #9c5e2f),
    linear-gradient(#fc0018, #fc0018);
  background-blend-mode: normal, normal;
  color: #3f1e11;
}

#Index > .cont .middle > .left ul li .tab {
  background-color: #050505;
  color: #c4a475;
  font-weight: bolder;
  border: 1px solid #c4a475;
  line-height: 32px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

#Index .cont .middle .left ul li img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

/* 动画部分 */

#Index .useranimated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>