<!-- 头部组件 -->
<template>
  <div id="HeaderTop">
    <!-- 顶部菜单 -->
    <div class="main">
      <ul class="home-main">
        <li @click="goHome">
          <i class="iconfont icon-shouyeshouye"></i>{{ $t("header.home") }}
        </li>
        <li>
          <i class="iconfont icon-zhanlvetouzi"></i>
          <span> {{ $t("header.tzdt") }} </span>
        </li>
      </ul>
      <ul class="user-main">
        <li v-if="hasLogin"><i class="iconfont icon-yonghutianchong"></i>{{user.nickname}}</li>
        <li v-if="hasLogin" class="color1 money">
          {{ $t("header.balance") }}:
          <span> ￥ {{user.money}} </span>
        </li>
        <li v-if="hasLogin" class="color2">
          <i class="iconfont icon-chongzhijiaofei"></i>{{ $t("header.cz") }}
        </li>
        <li v-if="hasLogin" class="color2"><i class="iconfont icon-tixian"></i>{{ $t("header.tx") }}</li>
        <li @click="showMainDialogFun">
          <i class="iconfont icon-gerenzhongxin"></i>{{ $t("header.grzx") }}
        </li>
        <!---->

        <li class="color3" v-if="hasLogin" @click="userTool">
          <i class="iconfont icon-tuichu"></i>{{ $t("header.exit") }}
        </li>
        <li class="color3" v-else @click="userTool">
          <!-- <i class="iconfont icon-tuichu"></i>退出 -->
          <i class="iconfont icon-bianji"></i>
          {{ $t("header.dlzc") }}
        </li>
        <li>
          <el-dropdown
            class="el-dropdown"
            custom-class="el-drodown-dd"
            trigger="click"
            :hide-on-click="true"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              <i
                class="iconfont"
                :class="[langIndex[$i18n.locale].iconfont]"
              ></i
              >{{ langIndex[$i18n.locale].name }}
            </span>
            <el-dropdown-menu class="el-dropdown-menu" slot="dropdown">
              <el-dropdown-item
                :command="item.sgin"
                v-for="(item, index) in languageList"
                :key="index"
              >
                <i class="iconfont" :class="[languageList[index].iconfont]"></i
                >{{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <!-- 弹窗主菜单 -->
    <MainDialog v-if="mainDialog" />
    <!-- 登录弹窗 -->
    <LoginDiv v-if="loginDialog" />
  </div>
</template>

<script>
import { setup } from "@/language/index.js";
import LangStorage from "@/language/lang";
import MainDialog from "@/components/mainDialog/mainDialog";
import LoginDiv from "@/components/loginDiv";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "HeaderTop",
  computed: { ...mapState(["mainDialog", "hasLogin", "loginDialog","user"]) },
  components: { MainDialog, LoginDiv },
  data() {
    return {
      langSgin: "zh",
      langIndex: {
        zh: {
          name: "简体中文",
          iconfont: "icon-jianti",
          sgin: "zh",
        },
        english: {
          name: "English",
          iconfont: "icon-yingwen",
          sgin: "english",
        },
        vietnamese: {
          name: "Tiếng Việt",
          iconfont: "icon-jianti",
          sgin: "vietnamese",
        }
      },
      languageList: [
        {
          name: "简体中文",
          iconfont: "icon-jianti",
          sgin: "zh",
        },
        {
          name: "English",
          iconfont: "icon-yingwen",
          sgin: "english",
        },
        {
          name: "Tiếng Việt",
          iconfont: "icon-jianti",
          sgin: "vietnamese",
        },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    ...mapMutations(["SHOWMAINDIALOG"]),
    ...mapActions(["LogOut"]),
    showMainDialogFun() {
      this.SHOWMAINDIALOG();
    },
    /* 退出登录按钮 */
    logOutFun() {
      this.$confirm(this.$t("header.exitMsg"), this.$t("common.msg"), {
        distinguishCancelAndClose: true,
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel")
      })
        .then(() => {
          this.$message("已退出");
          this.LogOut();
        })
        .catch((action) => {
          console.log("已经取消");
        });
    },
    /* 操作 */
    userTool() {
      // 登录或退出
      if (!this.hasLogin) {
        // 当前是没登录需要登录
        this.$store.commit("SHOWlOGINDIALOG");
      } else {
        this.logOutFun();
      }
    },
    /* 跳转首页 */
    goHome() {
      this.$router.push("/home");
    },
    handleCommand(e) {
      setup(e);
      LangStorage.setLang(e);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../css/header.less";

.el-dropdown-menu/deep/ .popper__arrow,
.el-dropdown-menu/deep/ .popper__arrow:after {
  border-bottom-color: #000;
}

.el-dropdown-menu /deep/ .el-dropdown-menu__item:hover {
  background-color: #262626;
}
</style>