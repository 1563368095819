<!-- 添加银行卡组件 -->
<template>
  <div id="AddBank">
    <div class="dialog-cont">
      <h1>{{$t('bankform.title')}}</h1>
      <el-form
        ref="form"
        class="add"
        :rules="rules"
        :model="form"
        label-width="160px"
      >
        <el-form-item :label="$t('bankform.bankname')" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('bankform.realname')" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('bankform.bankdm')" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('bankform.account')" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('bankform.bankadd')" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="warning">{{$t('common.confirm')}}</el-button>
        <el-button @click="close">{{$t('common.cancel')}}</el-button>
      </div>
    </div>
    <div class="black" @click="close"></div>
  </div>
</template>

<script>
import { mixin } from "@/mixins/popup";
export default {
  name: "AddBank",
  data() {
    return {
      form: {
        name: "张三",
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
      },
    };
  },
  mixins: [mixin],
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped lang="less">
#AddBank {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2008;

  .black {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .dialog-cont {
    width: 550px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    z-index: 1;
    padding-bottom: 15px;

    h1 {
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      background-image: linear-gradient(#dbd0b7, #dbd0b7),
        linear-gradient(#a56ceb, #a56ceb);
      color: #fff;
    }

    .add {
      padding: 0 25px;
      padding-top: 15px;
    }
  }
}
/* @import url(); 引入css类 */
</style>