import request from '@/utils/request'

export function productShow() {// 查询产品
    return request({
        url: 'api/product/index',
        method: 'GET'
    })
}

export function othersInvestment(params) {// 他人投资
    return request({
        url: `api/product/info?product_id=${params.product_id}&hall_id=${params.hall_id}`,
        method: 'GET'
    })
}

export function historyMarket(params) {// 历史详情
    return request({
        url: `api/product/history?product_id=${params.product_id}`,
        method: 'GET'
    })
}

export function palyInfo(params) {// 玩法
    return request({
        url: `api/product/play?product_id=${params.product_id}&type=${params.type}&hall_id=${params.hall_id}`,
        method: 'GET'
    })
}

export function inRecord(params) {// 投资记录
    return request({
        url: `api/product/mylist?product_id=${params.product_id}`,
        method: 'GET'
    })
}

export function placeOrder(params) {// 投资
    return request({
        url: `api/product/order`,
        method: 'POST',
        data: params
    })
}

export function topData(params) {// 顶部数据
    return request({
        url: `api/product/top?product_id=${params.product_id}`,
        method: 'GET'
    })
}


/* 房间密码 */
export function getchpwd(params) {
    return request({
        url: `api/product/check_password`,
        method: 'POST',
				data: params
    })
}

/* 房间密码 */
export function getapi(params) {
    return request({
        url: `api/index/service&appKey=api`,
        method: 'POST',
				data: params
    })
}

/* 查询固定额度 */
export function getEd(params) {
    return request({
        url: `api/product/special_money?hall_id=${params.hall_id}`,
        method: 'GET'
    })
}



