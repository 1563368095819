<!-- 返利规则 -->
<template>
  <div id="pop-content">
    <div id="scroll-content" class="tab scroll-style">
      <div name="pc280" id="pc28" class="active">
        <div class="item first">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAASCAIAAAARwPPWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTYzMjJBRDE0QzFDMTFFQjlCRjhCOEU2NjI0RjY3NjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTYzMjJBRDI0QzFDMTFFQjlCRjhCOEU2NjI0RjY3NjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCRUZCOTM3MDRDMUMxMUVCOUJGOEI4RTY2MjRGNjc2OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNjMyMkFEMDRDMUMxMUVCOUJGOEI4RTY2MjRGNjc2OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pq2t2SoAAABKSURBVHjabM7BDcAgDANAirr/Ap2vI0BwAggJYni0fp0sP3zJ+4SZu1l1GYwqcOkW8pFQqWz9dGeX2angI4O6qvJBb30pBmYIMADFgTFtzJUR3AAAAABJRU5ErkJggg=="
            alt=""
          />
          <h2>返利时间</h2>
          <p>每天null系统自动结算，派发</p>
          <br />
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAASCAIAAAARwPPWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTYzMjJBRDE0QzFDMTFFQjlCRjhCOEU2NjI0RjY3NjgiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTYzMjJBRDI0QzFDMTFFQjlCRjhCOEU2NjI0RjY3NjgiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCRUZCOTM3MDRDMUMxMUVCOUJGOEI4RTY2MjRGNjc2OCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpFNjMyMkFEMDRDMUMxMUVCOUJGOEI4RTY2MjRGNjc2OCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pq2t2SoAAABKSURBVHjabM7BDcAgDANAirr/Ap2vI0BwAggJYni0fp0sP3zJ+4SZu1l1GYwqcOkW8pFQqWz9dGeX2angI4O6qvJBb30pBmYIMADFgTFtzJUR3AAAAABJRU5ErkJggg=="
            alt=""
          />
          <h2>返利条件</h2>
          <p>组合（多单、多双、空单、空双）投资额 ≥ 投资总额1%</p>
        </div>
        <div class="item">
          <table class="content-rebet">
            <tr>
              <th>投资（当天）</th>
              <td colspan="2">返利金额</td>
            </tr>
            <tr>
              <th>1&lt;=亏损总额&lt;2</th>
              <td colspan="2">3 {{$t('common.CNY')}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RebateRules",
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped lang="less">
#pop-content {
  width: auto;
  height: 100%;
  overflow: auto;

  .tab {
    overflow: auto;
    position: relative;
  }

  .tab > div.active {
    background: #222127;
  }

  .item.first {
    padding: 10px 0 0 5px;
    margin-bottom: 0;
  }
  .item:first-child {
    padding-top: 15px;
  }
  .item {
    padding: 0 0 10px 20px;
    margin-bottom: 10px;

    img {
      float: left;
      position: relative;
      top: 0px;
    }
    h2 {
      position: relative;
      font-size: 16px;
      color: #999;
    }
    h2:before {
      content: "";
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 8px;
      border: 1px solid #333;
      margin-right: 7px;
      position: relative;
      top: -2px;
    }
    p {
      letter-spacing: 0;
      color: #fff;
      text-indent: 1em;
      padding-left: 0;
      margin: 5px 0;
    }

    table.content-rebet {
      text-align: center;
      width: 450px;
      border: 0;
      margin: 10px 0 0;
      border-collapse: collapse;
      border-spacing: 0;
      vertical-align: middle;

      tr {
        height: 35px;
      }

      th {
        background: #2e251f;
        color: #fae8cd;
        border-right-color: #fae8cd;
      }
    }
  }
}

#pop-content .item table.content-rebet td,
#pop-content .item table.content-rebet th {
  vertical-align: middle;
  border: 1px solid #999;
  color: #fff;
}
</style>