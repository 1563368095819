<!-- 充值 -->
<template>
  <div id="Recharge">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">{{ $t("recharge.tabIndex1") }}</el-menu-item>
      <el-menu-item index="2">{{ $t("recharge.tabIndex2") }}</el-menu-item>
    </el-menu>
    <!-- 线上充值 -->
    <div id="Online">
      <div id="RechargeTemplate" v-if="activeIndex === '1'">
        <p class="tit">{{ $t("recharge.czje") }}：</p>
        <div class="money-input">
          <el-input
            class="o-money"
            :placeholder="$t('recharge.placeHoder')"
            clearable
          ></el-input>
          <span class="right-unit">{{$t('common.CNY')}}</span>
          <span class="error-msg">{{ $t("recharge.msg1") }}100{{$t('common.CNY')}}</span>
        </div>
        <ul class="moneys">
          <li class="active">20</li>
          <li>100</li>
          <li>200</li>
          <li>1000</li>
        </ul>
        <p class="tit">{{ $t("recharge.msg3") }}</p>
      </div>
    </div>
    <!-- 线下充值 -->
    <div id="Offline" v-if="activeIndex === '2'">
      <div id="offlineRechargeTemplate">
        <p class="tit">{{ $t("recharge.czje") }}：</p>
        <div class="money-input">
          <el-input
            class="o-money"
            :placeholder="$t('recharge.placeHoder')"
            clearable
          ></el-input>
          <span class="right-unit">{{$t('common.CNY')}}</span>
          <span class="error-msg">{{ $t("recharge.msg1") }}100{{$t('common.CNY')}}</span>
        </div>
        <ul class="moneys">
          <li class="active">20</li>
          <li>100</li>
          <li>200</li>
          <li>1000</li>
        </ul>
        <p class="tit">{{ $t("recharge.msg3") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      activeIndex: "1",
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log("充值");
  },
  methods: {
    handleSelect(e) {
      this.activeIndex = e;
      console.log("切换了");
    },
  },
};
</script>
<style scoped lang="less">
#UserProCom {
  margin-left: 15px;
}

#app .theme-color1 .el-menu-demo {
  background-color: #222127;
}

#Recharge {
  .tit {
    color: #fff;
    margin: 17px 0 8px;
    font-size: 12px;
  }
  .right-unit {
    color: #fff;
    margin-left: 10px;
  }

  .error-msg {
    color: #fff;
    margin-left: 8px;
    letter-spacing: 1px;
  }

  .moneys {
    display: flex;
    margin-top: 11px;

    li {
      width: 80px;
      height: 35px;
      line-height: 35px;
      border: 1px solid #dcdcdc;
      text-align: center;
      margin-right: 8px;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
    }

    .active {
      color: #ffff1b;
      border: 1px solid #ffff1b;
    }
  }
}

/deep/#RechargeTemplate .money-input .o-money {
  width: 267px;
}

/deep/#RechargeTemplate .el-input__inner {
  background-color: transparent;
  background-image: none;
}

/deep/#offlineRechargeTemplate .money-input .o-money {
  width: 267px;
}

/deep/#offlineRechargeTemplate .el-input__inner {
  background-color: transparent;
  background-image: none;
}

/* @import url(); 引入css类 */
</style>