import axios from 'axios'
import { getToken } from '@/utils/auth'
import { Notification, MessageBox, Message } from 'element-ui'
import { Toast } from 'vant';
import store from '@/store'
import router from '@/router'
import Config from '@/settings'
import qs from 'qs'
import { isMobile } from "../../assets/js/tools"
import i18n from '../../language/index.js'

// 创建axios实例


const service = axios.create({
    // withCredentials: true,
    crossDomain: true,
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
    timeout: Config.timeout // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        if (config.method === 'POST') { // post请求时，处理数据
            config.data = qs.stringify({
                ...config.data //后台数据接收这块需要以表单形式提交数据，而axios中post默认的提交是json数据,所以这里选用qs模块来处理数据，也有其他处理方式，但个人觉得这个方式最简单好用
            })
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        }
        config.headers['lang'] = i18n.locale
        /* config.headers['Content-Type'] = 'application/x-www-form-urlencoded' */
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const code = response.data.code;
        if (code + '' === '401' || code + '' === '209') {
            if (isMobile()) {
                Toast(response.data.msg);
                store.dispatch('LogOut').then(() => {
                    router.push({ path: '/login' })
                })
            }
            return;
        }
        if (code < 200 || code > 300) {
            if (isMobile()) {
                Toast(response.data.msg);
            } else {
                Message.error({
                    message: response.data.msg
                })
            }
            return Promise.reject('error')
        } else {
            return response.data
        }
    },
    error => {
        let code = 0;
        try {
            code = error.response.data.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                Notification.error({
                    title: '网络请求超时',
                    duration: 5000
                })
                return Promise.reject(error)
            }
        }
        if (code) {
            if (code === 401) {
                MessageBox.confirm(
                    '登录状态已过期，您可以继续留在该页面，或者重新登录',
                    '系统提示',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).then(() => {
                    store.dispatch('LogOut').then(() => {
                        location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
            } else if (code === 403) {
                router.push({ path: '/401' })
            } else {

                const errorMsg = error.response.data.message
                if (errorMsg !== undefined) {
                    Notification.error({
                        title: errorMsg,
                        duration: 5000
                    })
                }
            }
        } else {
            if (isMobile()) {
                Toast('error');
            } else {
                Notification.error({
                    title: 'error',
                    duration: 5000
                })
            }

        }
        return Promise.reject(error)
    }
)

export default service