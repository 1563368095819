import request from '@/utils/request'
import qs from 'qs';

export function register(params) {
    return request({
        url: 'api/auth/register',
        method: 'POST',
        data: params
    })
}

export function loginDo(params) {
    console.log(params)
    return request({
        url: 'api/auth/login',
        method: 'POST',
        data: params
    })
}

/* 获取用户信息 */
export function getUserInfo(params) {
    console.log(params)
    return request({
        url: 'api/user/info',
        method: 'GET'
    })
}

/* 资金明细 （移动端） */
export function getTrader(params) {
    console.log(params)
    return request({
        url: `api/user/trader?page=${params.page}&limit=${params.limit}`,
        method: 'GET'
    })
}

/* 投资记录 （移动端） */
export function getOrder(params) {
    return request({
        url: `api/user/order?page=${params.page}&limit=${params.limit}`,
        method: 'GET'
    })
}
/* 查询提现 （通用） */
export function getWithdraw() {
    return request({
        url: `api/user/withdraw`,
        method: 'GET'
    })
}

/* 添加银行卡 （通用） */
export function bankAdd(params) {
    return request({
        url: `api/user/bank_add`,
        method: 'POST',
        data: params
    })
}

/* 添加币类型 （通用） */
export function bilianAdd(params) {
    return request({
        url: `api/user/bilian_add`,
        method: 'POST',
        data: params
    })
}

/* 查询银行卡 （通用） */
export function getBank() {
    return request({
        url: `api/user/bank`,
        method: 'GET'
    })
}

/* 查询碧莲 （通用） */
export function getBilian() {
    return request({
        url: `api/user/bilian`,
        method: 'GET'
    })
}

/* 查询我的等级 （通用） */
export function getUserLevel() {
    return request({
        url: `api/user/user_level`,
        method: 'GET'
    })
}

/* 查询安全中心 （通用） */
export function getSafety() {
    return request({
        url: `api/user/safety`,
        method: 'GET'
    })
}

/* 修改或者设置提现密码 （通用） */
export function editPayPassword(params) {
    return request({
        url: `api/user/edit_pay_password`,
        method: 'POST',
        data: params
    })
}

/* 修改提现密码 （通用） */
export function payPassword(params) {
    return request({
        url: `api/user/pay_password`,
        method: 'POST',
        data: params
    })
}

/* 修改密码 （通用） */
export function editPassword(params) {
    return request({
        url: `api/user/edit_password`,
        method: 'POST',
        data: params
    })
}


/* 获取消息 （通用） */
export function getMsg() {
    return request({
        url: `api/user/msg`,
        method: 'GET'
    })
}

/* 获取公告 */
export function getAnnotation() {
    return request({
        url: `api/user/annotation`,
        method: 'GET'
    })
}

/* 单个消息已读 （通用） */
export function readOne(params) {
    return request({
        url: `api/user/yidu?id=${params.id}`,
        method: 'GET'
    })
}

/* 全部消息已读 （通用） */
export function readAll() {
    return request({
        url: `api/user/all_yidu`,
        method: 'GET'
    })
}


/* 全部消息删除 （通用） */
export function msgDel() {
    return request({
        url: `api/user/del`,
        method: 'GET'
    })
}

/* 提现 （通用） */
export function withdrawDo(params) {
    return request({
        url: `api/user/withdraw`,
        method: 'POST',
        data: params
    })
}

/* 报表 （通用） */
export function report(params) {
    return request({
        url: `api/user/report?start=${params.start}&end=${params.end}`,
        method: 'GET'
    })
}

/* 银行列表 */
export function bank_list() {
    return request({
        url: `api/pay/bank_list`,
        method: 'GET'
    })
}
/* 调起收银台 */
export function bank_pay(params) {
    return request({
        url: `api/pay/pay`,
        method: 'POST',
				data: params
    })
}








