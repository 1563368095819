<!-- 新增数字货币 -->
<template>
  <div class="xd-modal">
    <div class="xd-modal-mask" @click="close"></div>
    <div class="xd-modal-wrapper dialog-cont" style="width: 510px">
      <h4 class="title">{{$t('biform.title')}}</h4>
      <section class="content">
        <el-form
          ref="form"
          class="add-usdt"
          :rules="rules"
          :model="form"
          label-width="120px"
        >
          <el-form-item :label="$t('biform.blx')" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('biform.realname')" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('biform.llx')" prop="name">
            <el-radio-group v-model="form.radio" size="mini">
              <el-radio label="1" border>BTC</el-radio>
              <el-radio label="2" border>HBTC</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('biform.skdz')" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-form>
      </section>
      <div class="dialog-footer">
        <el-button type="warning">{{$t('common.confirm')}}</el-button>
        <el-button @click="close">{{$t('common.cancel')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixins/popup";
export default {
  name: "AddDigital",
  data() {
    return {
      form: {
        name: "张三",
        radio: "1"
      },
      rules: {
        name: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
      },
    };
  },
  mixins: [mixin],
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped lang="less">
.xd-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  .xd-modal-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .dialog-cont .dialog-footer{
    text-align: center;
  }

  .xd-modal-wrapper {
    display: block;
    position: relative;
    margin: 280px auto 0;
    background-color: #fff;
    border-radius: 8px;
    z-index: 100;
    overflow: hidden;
    padding-bottom: 15px;

    .title {
      text-align: center;
      font-size: 15px;
      background-image: linear-gradient(#dbd0b7, #dbd0b7);
      height: 40x;
      line-height: 40px;
      font-weight: 400;
      color: #fff;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 20px 0;
      margin: 0 auto;
    }
  }
}
</style>