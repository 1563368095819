<!-- 提现 -->
<template>
  <div id="withdraw">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">{{ $t("withdrawal.tabIndex1") }}</el-menu-item>
    </el-menu>
    <!-- 提现主内容 -->
    <div class="with-box">
      <el-form ref="form" :model="form" label-width="180px">
        <el-form-item :label="$t('withdrawal.ktxye')">
          <label class="all-money">￥0</label>
        </el-form-item>
        <el-form-item :label="$t('withdrawal.txje')">
          <el-input
            class="o-money"
            :placeholder="$t('withdrawal.txjemsg')"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('withdrawal.txmm')">
          <el-input
            type="password"
            class="o-money"
            readonly
            @focus="passFocus"
            v-model="form.password"
          >
            <template slot="append">
              <i class="iconfont icon-jianpan-tianchong"></i>
            </template>
          </el-input>
          <div class="KeyBoard">
            <Keyboard
              @change="keyBoardChange"
              :inputType="'confirm'"
              v-if="PassFocus"
            />
            <div
              class="bg"
              v-if="PassFocus"
              @click="closeKeyboard"
              style=""
            ></div>
          </div>
        </el-form-item>
        <el-form-item :label="$t('withdrawal.tkzh')">
          <el-select v-model="form.quyu" :placeholder="$t('withdrawal.select')">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
          <span class="w-tit">{{$t('withdrawal.addBtn')}}</span>
        </el-form-item>
        <el-form-item>
          <el-button class="btn-withdraw" type="primary" @click="onSubmit"
            >{{$t('withdrawal.qrtx')}}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 提示内容 -->
    <div class="remind-info">
      <h1>{{$t('withdrawal.wxmsg')}}</h1>
      <div></div>
      <p>{{$t('withdrawal.msg')}}</p>
    </div>
  </div>
</template>

<script>
import Keyboard from "@/components/Keyboard";
export default {
  name: "Withdraw",
  components: { Keyboard },
  data() {
    return {
      activeIndex: "1",
      form: {
        name: "",
        password: "",
        quyu: "",
      },
      PassFocus: false,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log("提现");
    /* setTimeout(() => {
      this.noPersonInfo();
    }, 1000); */
  },
  methods: {
    /* 小键盘弹出 */
    passFocus() {
      this.PassFocus = true;
    },
    /* 关闭虚拟键盘 */
    closeKeyboard() {
      this.PassFocus = false;
    },
    /* 输入确认了 */
    keyBoardChange(e) {
      this.form.password = e.value;
      this.closeKeyboard();
    },
    /* 提交 */
    onSubmit() {
      console.log("提交了");
    },
    handleSelect() {
      console.log("切换了");
    },
    /* 缺少个人资料 */
    noPersonInfo() {
      this.$emit("jumpTab", {
        msg: "出错了",
        menuId: 1,
        tabIndex: "2",
      });
    },
  },
};
</script>
<style scoped lang="less">
#UserProCom {
  margin-left: 15px;
}

#app .theme-color1 .el-menu-demo {
  background-color: #222127;
}

#withdraw {
  .with-box {
    margin-top: 20px;
    .w-tit {
      color: #e23178;
      margin-left: 5px;
      cursor: pointer;
    }

    .btn-withdraw {
      background-color: #ffff1b;
      font-size: 16px;
      color: #000;
      border: none;
    }
  }

  .remind-info {
    margin-top: 20px;
    margin-bottom: 15px;
    padding-top: 15px;
    position: relative;
    z-index: 0;

    h1 {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 12px;
    }
  }
  .remind-info:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(#dbd0b7, #dbd0b7),
      linear-gradient(#8841e0, #8841e0);
  }
}

#withdraw .with-box .o-money {
  width: 350px;
}

#withdraw .el-form-item__content .all-money,
#withdraw .el-select .el-input__inner {
  color: #fff;
}

.KeyBoard .bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  min-height: 100vh;
  min-width: 100vw;
}

/* @import url(); 引入css类 */
</style>