<!-- 选择收款账户类型 -->
<template>
  <div class="xd-modal">
    <div class="xd-modal-mask" @click="close"></div>
    <div class="xd-modal-wrapper">
      <h4 class="title">{{ $t("checkType.title") }}</h4>
      <section class="content">
        <div class="add-btns">
          <button @click="choice('bank')">{{ $t("checkType.tab1") }}</button>
          <button @click="choice('usdt')">{{ $t("checkType.tab2") }}</button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixins/popup";
export default {
  name: "",
  data() {
    return {};
  },
  mixins: [mixin],
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    /* 选择了类型 */
    choice(e) {
      this.$emit("change", {
        event: "click",
        popName: "CHECKTYPE",
        value: e,
      });
    },
  },
};
</script>
<style scoped lang="less">
.xd-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;

  .xd-modal-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .xd-modal-wrapper {
    width: 200px;
    display: block;
    position: relative;
    margin: 280px auto 0;
    background-color: #fff;
    border-radius: 8px;
    z-index: 100;
    overflow: hidden;
    padding-bottom: 15px;

    .title {
      text-align: center;
      font-size: 15px;
      background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#dbd0b7),
        to(#dbd0b7)
      );
      background-image: linear-gradient(#dbd0b7, #dbd0b7);
      height: 40x;
      line-height: 40px;
      font-weight: 400;
      color: #fff;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 20px 0;
      margin: 0 auto;

      .add-btns button {
        display: block;
        width: 140px;
        height: 40px;
        border-radius: 5px;
        line-height: 40px;
        background-color: #f96;
        color: #fff;
        margin-bottom: 15px;
        font-size: 14px;
        cursor: pointer;
        list-style: none;
        font-style: normal;
        text-decoration: none;
        border: none;
        font-family: Microsoft Yahei, sans-serif;
      }
    }
  }
}
/* @import url(); 引入css类 */
</style>