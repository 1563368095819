<!-- 虚拟键盘 -->
<template>
  <div class="board" style="">
    <div class="title">{{ $t("configPass.qsrmm") }}</div>
    <div class="password">
      <span>{{ zw1 }}</span>
      <span>{{ zw2 }}</span>
      <span>{{ zw3 }}</span>
      <span>{{ zw4 }}</span>
    </div>
    <ul class="keys">
      <li @click="keyInput(item)" v-for="(item, index) in list" :key="index">
        {{ item.value }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    inputType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      zw1: "",
      zw2: "",
      zw3: "",
      zw4: "",
      password: [],
      list: [
        {
          type: "number",
          value: 1,
        },
        {
          type: "number",
          value: 2,
        },
        {
          type: "number",
          value: 3,
        },
        {
          type: "number",
          value: 4,
        },
        {
          type: "number",
          value: 5,
        },
        {
          type: "number",
          value: 6,
        },
        {
          type: "number",
          value: 7,
        },
        {
          type: "number",
          value: 8,
        },
        {
          type: "number",
          value: 9,
        },
        {
          type: "tool",
          event: "remove",
          value: this.$t('configPass.qc'),
        },
        {
          type: "number",
          value: 0,
        },
        {
          type: "tool",
          event: "confirm",
          value: this.$t('common.confirm'),
        },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    /* 模拟输入 */
    keyInput(e) {
      if (e.type == "number") {
        if (this.password.length >= 4) {
          this.$message({
            type: "warning",
            message: "最多输入4位",
          });
          return;
        }
        this.password.push(e.value);
        this.zwFun(this.password.length);
      } else if (e.type == "tool" && e.event == "remove") {
        // 清空
        this.reset();
      } else if (e.type == "tool" && e.event == "confirm") {
        this.confirm();
      }
    },
    /* 确认 */
    confirm() {
      if (this.password.length < 4) {
        this.$message({
          type: "warning",
          message: "请输入完整",
        });
        return;
      }
      let event = "none";
      if (this.inputType === "new") {
        // 新密码
        event = "new";
      }else if (this.inputType === "confirm") {
        // 确认密码
        event = "confirm";
      }
      this.$emit('change', {
          event,
          value: this.password.join('')
      })
    },
    /* 清空 */
    reset() {
      this.zw1 = "";
      this.zw2 = "";
      this.zw3 = "";
      this.zw4 = "";
      this.password = [];
    },
    /* 占位 */
    zwFun(value) {
      if (value + "" === "1") {
        this.zw1 = "*";
      } else if (value + "" === "2") {
        this.zw2 = "*";
      } else if (value + "" === "3") {
        this.zw3 = "*";
      } else if (value + "" === "4") {
        this.zw4 = "*";
      }
    },
  },
};
</script>
<style scoped lang="less">
.board {
  position: absolute;
  z-index: 99;
  width: 216px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #bb9754;
  overflow: hidden;

  .title {
    background-image: linear-gradient(#dbd0b7, #dbd0b7),
      linear-gradient(#a56ceb, #a56ceb);
    background-blend-mode: normal, normal;
    height: 34px;
    line-height: 34px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  .password {
    text-align: center;
    margin: 12px 0 15px;

    span {
      width: 35px;
      height: 25px;
      line-height: 30px;
      display: inline-block;
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: #dbd0b7;
      margin: 0;
      padding: 0;
      margin-right: -6px;
      color: #672daf;
      vertical-align: top;
    }

    span:last-of-type {
      border-width: 1px;
    }
  }

  .keys {
    width: 175px;
    margin: 0 auto;
    margin-bottom: 16px;
    line-height: 25px;

    li {
      margin: 0;
      padding: 0;
      display: inline-block;
      width: 55px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: #dbd0b7;
      color: #d6a23e;
      font-size: 12px;
      cursor: pointer;
    }

    li:active {
      background-color: #dbd0b7;
      color: #fff;
      cursor: pointer;
    }

    li:nth-child(3n) {
      border-width: 1px 1px 0;
    }
    li:last-of-type {
      border-width: 1px;
    }

    li:nth-last-of-type(2),
    li:nth-last-of-type(3) {
      border-width: 1px 0 1px 1px;
    }
  }
}
</style>