<!-- 投资记录 -->
<template>
  <div id="UserRecords">
    <div class="scroll-style">
      <div id="Record" class="LotteryRecords">
        <div>
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t('records.cp')">
              <el-select v-model="formInline.region" :placeholder="$t('records.all')">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('records.lunshu')">
              <el-input v-model="formInline.user" :placeholder="$t('records.lunshu')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('records.statu')">
              <el-select v-model="formInline.region" :placeholder="$t('records.all')">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="dates" :label="$t('records.time')">
              <el-date-picker type="date" :placeholder="$t('records.start')">
              </el-date-picker>
            </el-form-item>
            <el-form-item class="dates" label="-">
              <el-date-picker type="date" :placeholder="$t('records.end')">
              </el-date-picker>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" @click="onSubmit"
                >{{ $t('records.search') }}</el-button
              >
            </el-form-item>
          </el-form>
          <el-table :data="tableData" border :empty-text="$t('records.nodata')" style="width: 100%">
            <el-table-column
              prop="banknum"
              align="center"
              :label="$t('records.tzcp')"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="bankname" align="center" :label="$t('records.tzType')">
            </el-table-column>
            <el-table-column prop="address" align="center" :label="$t('records.lunshu')">
            </el-table-column>
            <el-table-column prop="name" align="center" :label="$t('records.tzMoney')">
            </el-table-column>
            <el-table-column prop="date" align="center" :label="$t('records.statu')">
            </el-table-column>
            <el-table-column prop="name" align="center" :label="$t('records.hlje')">
            </el-table-column>
            <el-table-column prop="name" align="center" :label="$t('records.txTime')">
            </el-table-column>
            <el-table-column prop="name" align="center" :label="$t('records.caozuo')">
            </el-table-column>
          </el-table>
          <el-pagination
            class="page"
            background
            layout="prev, pager, next"
            :total="1"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserRecords",
  data() {
    return {
      formInline: {
        user: "",
        region: "",
      },
      tableData: [],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>
<style scoped lang="less">
#UserRecords {
  position: relative;
  height: 100%;
  overflow: hidden;
}

#UserRecords > div {
  padding: 0 14px;
  height: 100%;
  overflow: scroll;
}

#UserRecords .el-form--inline .el-form-item {
  margin-right: 5px;
}
#UserRecords .el-form-item {
  margin-bottom: 0;
}

#UserRecords .el-button {
  padding: 7px 10px;
  margin-left: 5px;
}

#UserRecords .el-button--primary {
  background-color: #ffff1b;
  border-color: #ffff1b;
  color: #000;
}
</style>