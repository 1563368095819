import Home from "../views/pc/index"
import Cny from "../views/pc/cny"
import Mcny from "../views/mc/mcny/index"
export const routes = [
    {
        path: "/",
        name: "Home",
        redirect: "/home",
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/cny',
        name: 'Cny',
        meta: {isAuth: true},
        component: Cny
    },
    {
        path: '/investment',
        name: 'Investment',
        meta: {isAuth: true},
        component: () => import("@/views/pc/investment")
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import("@/views/pc/register")
    },
    /* 移动端路由 */
    {
        path: '/index',
        name: 'pcIndex',
        component: () => import("@/views/mc/index")
    }, {
        path: '/recharge',
        name: 'Recharge',
        meta: {isAuth: true},
        component: () => import("@/views/mc/tabbar/recharge")
    }, {
        path: "/mcny",
        name: "Mcny",
        meta: {isAuth: true},
        component: () => import("@/views/mc/mcny/index")
    }, {
        path: "/trading",
        name: "Trading",
        meta: {isAuth: true},
        component: () => import("@/views/mc/trading/index")
    }, {
        path: "/login",
        name: "Login",
        component: () => import("@/views/mc/login/index")
    }, {
        path: "/mregister",
        name: "Mregister",
        component: () => import("@/views/mc/register/index")
    }, {
        path: "/user",
        name: "User",
        component: () => import("@/views/mc/tabbar/user")
    }, {
        path: "/usersetting",
        name: "Usersetting",
        meta: {isAuth: true},
        component: () => import("@/views/mc/usersetting/index")
    }, {
        path: "/marketMovements",
        name: "MarketMovements",
        component: () => import("@/views/mc/tabbar/marketMovements")
    }, {
        path: "/exchangeRate",
        name: "ExchangeRate",
        component: () => import("@/views/mc/exchangeRate/index")
    }, {
        path: "/information",
        name: "Information",
        component: () => import("@/views/mc/information/index")
    }, {
        path: "/help",
        name: "Help",
        component: () => import("@/views/mc/help/index")
    }, {
        path: "/vipgrade",
        name: "Vipgrade",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/vipgrade")
    }, {
        path: "/userfunds",
        name: "Userfunds",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/userfunds")
    }, {
        path: "/orders",
        name: "Orders",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/orders")
    }, {
        path: "/message",
        name: "Message",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/message")
    }, {
        path: "/usersecurity",
        name: "Usersecurity",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/usersecurity")
    }, {
        path: "/hallrule",
        name: "Hallrule",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/hallrule")
    }, {
        path: "/finance",
        name: "Finance",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/finance")
    }, {
        path: "/withdraw",
        name: "Withdraw",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/withdraw")
    }, {
        path: "/addBand",
        name: "AddBand",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/addBand")
    }, {
        path: "/currency",
        name: "Currency",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/currency")
    }, {
        path: "/langsetting",
        name: "Langsetting",
        component: () => import("@/views/mc/user/langsetting")
    }, {
        path: "/cusService",
        name: "CusService",
        meta: {isAuth: true},
        component: () => import("@/views/cusService/index")
    }, {
        path: "/deflutService",
        name: "DeflutService",
        component: () => import("@/views/deflutService/index")
    }, {
        path: "/usercipherpassword",
        name: "Usercipherpassword",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/usercipherpassword")
    }, {
        path: "/userloginpassword",
        name: "Userloginpassword",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/userloginpassword")
    }, {
        path: "/reportForms",
        name: "ReportForms",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/reportForms")
    },{
        path: "/productorder",
        name: "productorder",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/productorder")
    },{
        path: "/productrule",
        name: "productrule",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/productrule")
    },{
        path: "/capital",
        name: "capital",
        meta: {isAuth: true},
        component: () => import("@/views/mc/user/capital")
    }
    
]