import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import 'reset-css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from './language/index.js'
import 'swiper/css/swiper.css'
import ElementUI from "element-ui";
import store from "./store";
import 'animate.css';
import '@/assets/js/common.js'
import { Picker, NavBar, Toast, Switch, Form, Button, Field, Dialog, Circle, List, Popup, Empty, Tab, Tabs, Cell, CellGroup, ActionSheet, DatetimePicker,Popover } from 'vant';
import "vant/lib/index.less";
import  * as filters from "@/plugin/filters.js";
import  * as segmentation from "@/plugin/segmentation.js";


Vue.use(NavBar);
Vue.use(Toast);
Vue.use(Switch);
Vue.use(Form);
Vue.use(Button);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Circle);
Vue.use(List);
Vue.use(Popup);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Popover);
Vue.use(Picker);

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])//插入过滤器名和对应方法
})
Object.keys(segmentation).forEach(key=>{
    Vue.filter(key,segmentation[key])//插入过滤器名和对应方法
})

// 关闭vue的生产提示
Vue.config.productionTip = false;

new Vue({
  i18n,
  render: h => h(App),
  store,
  router
}).$mount('#app')
