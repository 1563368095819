<!-- 弹窗组件 -->
<template>
  <div id="RoomDialog">
    <div class="content" :class="[isShow ? 'show' : 'hide']">
      <div class="cont">
        <div class="close" @click="close">
          <i class="iconfont icon-guanbi"></i>
        </div>
        <div class="title">BTC</div>
        <ul class="hall-list">
          <li
            class="hall-item"
            v-for="(item, index) in prolist.list"
            :key="index"
            @click="checkFun(item)"
          >
            <img
              v-if="item.type == 0"
              src="../../assets/img/dialog01.png"
              class="hall-bg"
            />
            <img
              v-else-if="item.type == 1"
              src="../../assets/img/dialog02.png"
              class="hall-bg"
            />
            <img
              v-else-if="item.type == 2"
              src="../../assets/img/dialog03.png"
              class="hall-bg"
            />
            <img
              v-else-if="item.type == 3"
              src="../../assets/img/dialog04.png"
              class="hall-bg"
            />
            <div class="hall-content">
              <p class="hall-number">
                <i class="iconfont icon-yonghu"></i>
                <span> 交易人数 8159 人</span>
              </p>
              <p class="hall-name">
                {{ item.name }}
                <span>({{ item.shuoming }})</span>
              </p>
              <p>最低买入 {{ item.min }}</p>
              <p>进入最低金额 {{ item.minmoney }}</p>
            </div>
          </li>
          <!-- <li class="hall-item">
            <img src="../../assets/img/dialog02.png" class="hall-bg" />
            <div class="hall-content">
              <p class="hall-number">
                <i class="iconfont icon-yonghu"></i>
                <span> 交易人数 9573 人</span>
              </p>
              <p class="hall-name">
                中级
                <span>(INTERMEDIATE)</span>
              </p>
              <p>最低买入 1</p>
              <p>进入最低金额 1,000</p>
            </div>
          </li>
          <li class="hall-item">
            <img src="../../assets/img/dialog03.png" class="hall-bg" />
            <div class="hall-content">
              <p class="hall-number">
                <i class="iconfont icon-yonghu"></i>
                <span> 交易人数 8434 人</span>
              </p>
              <p class="hall-name">
                高级
                <span>(ADVANCED)</span>
              </p>
              <p>最低买入 1</p>
              <p>进入最低金额 3,000</p>
            </div>
          </li>
          <li class="hall-item">
            <img src="../../assets/img/dialog04.png" class="hall-bg" />
            <div class="hall-content">
              <p class="hall-number">
                <i class="iconfont icon-yonghu"></i>
                <span> 交易人数 9840 人</span>
              </p>
              <p class="hall-name">
                贵宾
                <span>(VIP Room)</span>
              </p>
              <p>最低买入 10</p>
              <p>进入最低金额 3,000</p>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    prolist: {
      type: Object,
      default: {
        list: []
      },
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    close() {
      this.isShow = false;
      setTimeout(() => {
        this.$emit("change", {
          type: "close",
        });
      }, 400);
    },
    // 选中
    checkFun(item) {
      this.$emit("skip", {
        chidId: item.id,
        pid: this.prolist.id,
        type: item.type
      });
    },
  },
};
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
@import "index.less";
</style>