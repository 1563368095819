import Vue from 'vue';
import Vuex from 'vuex';
import { getUserInfo } from '../api/user';
import { getToken, setToken, removeToken } from '@/utils/auth'

const actions = {
    // 获取用户信息
    GetInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getUserInfo().then(res => {
                commit('SET_USER', res.data)
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    closeMainDialog(store) {
        setTimeout(() => {
            store.commit('CLOSEMAINDIALOG')
        }, 400)
    },
    closeLoginDialog(store) {
        setTimeout(() => {
            store.commit('CLOSElOGINDIALOG')
        }, 400)
    },
    SetToken(store, provider) {
        console.log(provider);
        store.commit('SET_TOKEN', provider.token);
    },
    // 退出登录
    LogOut({ commit }) {
        commit('REMOVE_TOKEN', '')
        removeToken();
    },
};
const mutations = {
    CLOSEMAINDIALOG(state) {
        state.mainDialog = false;
    },
    SHOWMAINDIALOG(state) {
        state.mainDialog = true;
    },
    /* 弹出登录框 */
    SHOWlOGINDIALOG(state) {
        state.loginDialog = true;
    },
    /* 关闭登录框 */
    CLOSElOGINDIALOG(state) {
        state.loginDialog = false;
    },
    REMOVE_TOKEN: (state) => {
        state.token = '';
        state.user = {};
        state.hasLogin = false;
    },
    SET_TOKEN: (state, token) => {
        state.token = token
        state.hasLogin = true;
        console.log("用户信息存储成功")
        /* state.loginDialog = false; */
        setToken(token)
    },
    HASLOGIN: (state) => {
        state.hasLogin = true;
    },
    SET_USER: (state, user) => {
        state.user = user
    },
};
const state = {
    token: getToken(),
    hasLogin: false,// 是否登录了
    loginDialog: false,
    mainDialog: false,
    user: {},
};

Vue.use(Vuex);

export default new Vuex.Store({
    actions,
    mutations,
    state
})