export const isMobile = () => {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
};

export const isPcPath = (str) => {
    let menuArr = ['/home','/cny','/investment','/register'];
    return menuArr.indexOf(str)
    // return str.indexof(/(home|cny|investment|register)/i);
}