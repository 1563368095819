<!-- 设置密码 -->
<template>
  <div id="SecurityDialog">
    <div class="cont">
      <h1>{{ $t("configPass.title") }}</h1>
      <div class="dia-body dialog-cont">
        <!-- 内容 （可能会有多种）-->
        <div id="UserWithdrawPW">
          <div>
            <el-form
              ref="form"
              class="add-form"
              :model="form"
              label-width="160px"
            >
              <el-form-item :label="$t('configPass.newPass')">
                <el-input
                  readonly
                  type="password"
                  @focus="newPassFocus"
                  v-model="form.newPass"
                >
                  <template slot="append">
                    <i class="iconfont icon-jianpan-tianchong"></i>
                  </template>
                </el-input>
                <div class="KeyBoard">
                  <Keyboard
                    @change="keyBoardChange"
                    :inputType="'new'"
                    v-if="isNewPassFocus"
                  />
                  <div
                    class="bg"
                    v-if="isNewPassFocus"
                    @click="closeKeyboard"
                    style=""
                  ></div>
                </div>
              </el-form-item>
              <el-form-item :label="$t('configPass.confrimPass')">
                <el-input
                  readonly
                  type="password"
                  @focus="confirmPassFocus"
                  v-model="form.confirmPass"
                >
                  <template slot="append">
                    <i class="iconfont icon-jianpan-tianchong"></i>
                  </template>
                </el-input>
                <div class="KeyBoard">
                  <Keyboard
                    @change="keyBoardChange"
                    :inputType="'confirm'"
                    v-if="isConfirmPassFocus"
                  />
                  <div
                    class="bg"
                    v-if="isConfirmPassFocus"
                    @click="closeKeyboard"
                    style=""
                  ></div>
                </div>
              </el-form-item>
            </el-form>
            <div class="dialog-footer">
              <el-button @click="close">{{ $t("common.cancel") }}</el-button>
              <el-button type="warning">{{ $t("common.confirm") }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="black"></div>
  </div>
</template>

<script>
import { mixin } from "@/mixins/popup";
import Keyboard from "@/components/Keyboard";
export default {
  name: "SecurityDialog",
  components: { Keyboard },
  data() {
    return {
      form: {
        newPass: "",
        confirmPass: "",
      },
      isNewPassFocus: false, // 新密码输入框是否获取到了焦点
      isConfirmPassFocus: false, // 确认密码输入框是否获取到了焦点
    };
  },
  mixins: [mixin],
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    /* 关闭虚拟键盘 */
    closeKeyboard() {
      this.isNewPassFocus = false;
      this.isConfirmPassFocus = false;
    },
    /* 新密码输入框获取焦点 */
    newPassFocus() {
      this.isNewPassFocus = true;
      console.log("获取到焦点了");
    },
    /* 确认密码输入框获取焦点 */
    confirmPassFocus() {
      this.isConfirmPassFocus = true;
    },
    /* 输入确认了 */
    keyBoardChange(e) {
      console.log(e);
      if (e.event === "new") {
        this.form.newPass = e.value;
        this.isNewPassFocus = false;
      } else if (e.event === "confirm") {
        this.form.confirmPass = e.value;
        this.isConfirmPassFocus = false;
      }
    },
  },
};
</script>
<style scoped lang="less">
#SecurityDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .cont {
    width: 600px;
    background-color: #222127;
    border-radius: 10px;
    z-index: 2007;
    h1 {
      height: 60px;
      line-height: 60px;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      border-radius: 10px 10px 0 0;
      background-image: url(../../assets/img/title.png);
      color: #fff;
    }

    .dia-body {
      padding: 16px 20px;
    }
  }
}
/* 内容部分 */
#UserWithdrawPW {
  .add-form {
    margin-top: 10px;
  }
}

.black {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog-cont .dialog-footer {
  text-align: center;
}

.KeyBoard .bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  min-height: 100vh;
  min-width: 100vw;
}
</style>