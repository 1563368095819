<!-- 轮播图 -->
<template>
  <div class="banner_warp">
    <swiper class="banner" ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <img
          src="https://static.flsystem199.com/alpe/04b870bfa84b8d6bf0a5b01af76e4205.png"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="https://static.flsystem199.com/alpe/04b870bfa84b8d6bf0a5b01af76e4205.png"
        />
      </swiper-slide>
      <swiper-slide>
        <img
          src="https://static.flsystem199.com/alpe/04b870bfa84b8d6bf0a5b01af76e4205.png"
        />
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="pagination"></div>
      <div class="swiper-button-next" slot="pagination"></div>
    </swiper>
    <div class="notice-wrapper">
      <!-- 公告通知 -->
      <div class="n-marquee">
        <div id="MySwiper">
          <div
            class="box dis-flex"
            style="transform: translateY(0px) translateX(0px)"
          >
            <ul>
              <div class="notice-con"></div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // Some Swiper option/callback...
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
  },
};
</script>
<style scoped lang="less">
.banner_warp {
    position: relative;
}
.banner {
  height: 100%;
  max-height: 475px;

  .swiper-slide img {
    height: 100%;
    max-height: 475px;
    display: block;
    margin: 0 auto;
    -webkit-box-shadow: 0 2px 8px 0 #9f9f9f;
    box-shadow: 0 2px 8px 0 #9f9f9f;
  }
}

.swiper-container {
  .swiper-button-next,
  .swiper-button-prev {
    height: 84px;
    width: 39px;
    margin-right: 0 !important;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 300;
  }

  .swiper-button-prev {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAABUCAMAAAA2y95oAAAAgVBMVEUAAAAAAAAAAADo6OgAAAAAAADU1NSoqKiCgoJhYWEPDw8AAAAAAAAAAAAAAAD9/f339/fy8vLu7u7FxcVwcHBISEg9PT02NjYjIyMAAAD09PTr6+vg4ODZ2dnR0dHJycnAwMC+vr60tLSbm5uVlZWOjo55eXlWVlYxMTEAAAD///+QbMzpAAAAKnRSTlNmACHhY0TMqZKEalpXNyT89Ozov4t7d3VvW/Dl2NHJwbu4saCdmo+Aczu9iR9gAAAAqUlEQVRIx+3XRxKCUBBF0ZYgIEgSFHNOb/8LtIoF6B18Z/+OT/W4n30tKuJgMmY/K0ZpoPnoGDRUAN0aOiuhi6HLoYvM5/P9q0WL2EkHdE1nwo66EDZV4pDtdSVspxthoe6MpUANoToDtcqMlGhqqFQhhdsZgg81DGbaUFi/EHyqWiLYSwyupJ7BSh2CQ92Yz+dzXARd7viPLd3+2YHTHfB2tlOiPC4nYx++OAkBkO5WJQAAAABJRU5ErkJggg==)
      no-repeat;
    left: -39px;
  }

  .swiper-button-next {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAABUCAMAAAA2y95oAAAAbFBMVEUAAAAAAADY2NhUVFTo6OgeHh4AAAAAAAAAAADz8/O+vr6jo6OLi4sAAAAAAAAAAAAAAAD7+/v39/fv7+/m5uaTk5N3d3d1dXVmZmYVFRUJCQkAAAD5+fng4ODf39+Dg4NHR0cAAAAAAAD////plcsSAAAAI3RSTlNmANB/4m5jWETvuKWYWzckIvny6N2cjoyHa2gg9tjWlHpXO4L+DDAAAACpSURBVEjH7dc3EsMwDAVRUDlT0ZbkbNz/jvbwANYWnHHDrV+H5kOMqy/yTH7l3CUX14HrBASZmF6Ya5mzwiqga6HLJBQK/akkYq5SCGsKF70z2GhC4czgSacdwnJDcNUyRTDWK4XlE8FUB3jBirBIa4/spgthiTaMPYDaJj0Dto+IpaOu5FyDxgKavywUCrl4GXQvzzvWCt7Zfne76aAzb8D4n+KyR3/PBx6XB580Uz63AAAAAElFTkSuQmCC)
      no-repeat;
    right: -39px;
  }
}

.swiper-container:hover .swiper-button-prev {
  left: 0;
  transition: all 0.3s ease-in-out;
}

.swiper-container:hover .swiper-button-next {
  right: 0;
  transition: all 0.3s ease-in-out;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "";
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "";
}

.notice-wrapper {
  width: 865px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  height: 54px;
  overflow: hidden;
  bottom: 20px;
  z-index: 10;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px 0 70px;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: 50% url(../assets/img/tzl.png) no-repeat;
}
/* @import url(); 引入css类 */
</style>