
module.exports = {
    /**
 * @description token key
 */
    TokenKey: 'TOEKN',
    /**
  * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
  */
    tokenCookieExpires: 1,
    /**
     * @description 请求超时时间，毫秒（默认2分钟）
     */
    timeout: 1200000
}